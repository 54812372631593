<template>
  <div>
    <x-header>
      <img
        slot="headerImage"
        src="../../../assets/guojimaoyi/banner.jpg"
        alt=""
      />
      <h1 slot="headerTitle">
       {{$store.state.navShow == true ? '国际贸易——构架更高的国际视野 创造无界的服务价值':'International trade - import and export trade service provider for global customers'}}
      </h1>
    </x-header>
    <div class="guojimaoyi">
      <!-- <p>
        MG集团境外贸易合作广泛，与俄罗斯、白俄罗斯、德国政府、龙头企业、进出口企业均有长期合作，可精准匹配两国采购商与供应商的供需关系，实现进出口贸易对接。运用在物流、清关等方面的优势，将沿线国家的粮食、肉食、羊绒产品、化工、木材、海产品、矿产运回国内，形成生产、加工、运输、生活服务等产业链的聚集。目前，合作的俄罗斯企业超过140家。
      </p> -->
      <p>
        {{$store.state.navShow == true ? 'MG集团贸易主业在能源、粮食、肉类、大宗商品等领域，深耕俄罗斯本土贸易供应链优势，提供的优质产品和专业服务广泛应用于社会生产和衣食住行，在国内外享有良好声誉。':"MG Group's main trade business is in the fields of energy, grain, meat, bulk commodities and so on. It has dug deeply the advantages of Russia's local trade supply chain. The high-quality products and professional services it provides are widely used in social production, clothing, food, housing and transportation, and enjoy a good reputation at home and abroad."}}
      </p>

      <div class="guojimaoyiImage">
        <div
          class="guojimaoyiImageCon"
          v-for="(item, index) in $store.state.navShow == true ? timage : timageE"
          :key="index"
        >
          <img data-aos="flip-down" :src="item.signImg" alt="" />
          <div class="guojimaoyiImageText">{{ item.text }}</div>
        </div>
      </div>
      <!-- MG集团与俄罗斯政府和龙头企业深度合作 -->
      <!-- <div class="deep_cooperate">
        <p class="TitleP">MG集团与俄罗斯政府和龙头企业深度合作</p>
        <div class="cooperate_main guojimaoyi_kuang" data-aos="fade-right">
          <img src="../../../assets/guojimaoyi/xiaomi.jpg" alt="" />
        </div>
      </div> -->

      <!-- 俄罗斯最大的三家超市的供货商X5零售集团 -->
      <!-- <div class="supplier">
        <p class="TitleP">俄罗斯最大的三家超市的供货商X5零售集团</p>
        <div class="supplier_main guojimaoyi_kuang" data-aos="fade-left">
          <img src="../../../assets/guojimaoyi/supplier.jpg" alt="" />
        </div>
      </div> -->

      <!-- 俄罗斯肉食龙头塞尔那维雅、布莱恩斯基等企业 -->
      <!-- <div class="RussianMeat_Belarus leadingEnterprise">
        <p class="TitleP">俄罗斯肉食龙头塞尔那维雅、布莱恩斯基等企业</p>
        <div
          class="RussianMeat_Belarus_main guojimaoyi_kuang"
          data-aos="fade-right"
        >
          <img src="../../../assets/guojimaoyi/RussianMeat.jpg" alt="" />
        </div>
      </div> -->

      <!-- 白俄罗斯进口泥炭土，用于农业种植和改良土壤环境 -->
      <!-- <div class="RussianMeat_Belarus BelarusianPeat">
        <p class="TitleP">白俄罗斯进口泥炭土，用于农业种植和改良土壤环境</p>
        <div
          class="RussianMeat_Belarus_main guojimaoyi_kuang"
          data-aos="fade-left"
        >
          <img src="../../../assets/guojimaoyi/Belarus.jpg" alt="" />
        </div>
      </div> -->

      <!-- 贸易对接 -->
      <!-- <div class="trade_docking">
        <p class="TitleP">贸易对接</p>
        <div class="neirong_kuang">
          <div
            class="neirong_items"
            v-for="(item, index) in tradDockingArr"
            :key="index"
            data-aos="zoom-out-up"
          >
            <img :src="item.image" alt="" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div> -->

      <div class="main_content">
        <!-- <p>
          MG集团在境外拥有本土化运营团队，在政策文化、运营机制、法务财务、业务操作方面可以提供咨询服务，除此之外，在中俄两国间还可提供政府对接、招商引资、科技创新、技术合作、人才引进等方面的增值服务。
        </p> -->
        <p>
          {{$store.state.navShow == true ? 'MG集团是中俄贸易供应链领跑者，服务于对俄出口方向的中国企业超过1000家。2021年4月至今，外贸货品交易额超100种品类，其中机械电子、电气设备、皮毛制品、服装鞋帽、车辆配件外贸出口总量超过146万件，贸易总额超过14亿元。致力将进出口贸易与海外资源相链接，打造国际贸易闭环，加速国际贸易流通。':'MG Group is a leader in the Sino-Russian trade supply chain, with more than 1000 Chinese enterprises serving the export direction to Russia. Since April 2021, the trade volume of foreign trade goods has exceeded 100 categories, including machinery and electronics, electrical equipment, fur products, clothing, shoes and hats, vehicle accessories, with a total export volume of more than 1.46 million pieces, and a total trade volume of more than 1.4 billion yuan. We are committed to linking import and export trade with overseas resources, creating a closed loop of international trade and accelerating international trade circulation.'}}
        </p>

        <!-- 标志图片 -->
        <!-- <div class="signImg">
          <div
            class="itemsImg"
            v-for="(item, index) in signImgArr"
            :key="index"
            data-aos="flip-down"
            :data-aos-delay="Number(index * 100)"
          >
            <img :src="item.signImg" alt="图片加载失败" />
          </div>
        </div> -->

        <!-- 交流活动 -->
        <!-- <div class="communication_activities">
          <p>交流活动</p>
          <img
            src="../../../assets/shangwufuwu/communication_activities.jpg"
            alt=""
          />
        </div> -->
      </div>

      <div class="imgT5">
        <img v-if="$store.state.navShow == true" src="../../../assets/guojimaoyi/t5.jpg" alt="" />
        <img v-else src="../../../assets/guojimaoyi/t5E.jpg" alt="" />

        <div class="introduce">
          <div class="introduceCon">
            <div class="introduceCon_Top">
              <div class="introduceCon_TopOne">

                 <countTo :startVal="0" :endVal="1000" :duration="3000"></countTo>
              </div>
              <div class="introduceCon_TopTwo">{{$store.state.navShow == true ? '家' : ''}}</div>
            </div>
            <div class="introduceCon_But">{{$store.state.navShow == true ? '服务于对俄出口方向中国企业超':'Serving over Chinese enterprises exporting to Russia'}}</div>
          </div>
          <div class="introduceCon">
            <div class="introduceCon_Top">
              <div class="introduceCon_TopOne"><countTo :startVal="0" :endVal="100" :duration="3000"></countTo></div>
              <div class="introduceCon_TopTwo">{{$store.state.navShow == true ? '种' : ''}}</div>
            </div>
            <div class="introduceCon_But">{{$store.state.navShow == true ? '外贸货品交易额超':'Over kinds of foreign trade goods'}}</div>
          </div>
          <div class="introduceCon">
            <div class="introduceCon_Top">
              <div class="introduceCon_TopOne"><countTo :startVal="0" :endVal="146" :duration="3000"></countTo></div>
              <div class="introduceCon_TopTwo">{{$store.state.navShow == true ? '万件':''}}</div>
            </div>
            <div class="introduceCon_But">{{$store.state.navShow == true ? '外贸出口量超':'Foreign trade export volume exceeded'}}</div>
          </div>
          <div class="introduceCon">
            <div class="introduceCon_Top">
              <div class="introduceCon_TopOne"><countTo :startVal="0" :endVal="14" :duration="3000"></countTo></div>
              <div class="introduceCon_TopTwo">{{$store.state.navShow == true ? '亿元':''}}</div>
            </div>
            <div class="introduceCon_But">{{$store.state.navShow == true ? '货品交易额超':'The transaction volume of goods exceeds  '}}</div>
          </div>
        </div>
      </div>

      <div class="textT6">
        <div class="textT6Title">{{$store.state.navShow == true ? '应对新变化 创造新价值':'Respond to new changes and create new value'}}</div>
        <div class="textT6Text">
          {{$store.state.navShow == true ? 'MG集团将架构国际间外贸流通新渠道、新生态，以迸发社会经济共生动力，推动区域经济社会实现可持续增长。':'MG Group will build a new channel and new ecology for international foreign trade circulation so as to stimulate the power of social and economic symbiosis and to promote the sustainable growth of the regional economy and society.'}}
        </div>
      </div>

      <!-- 相关业务 -->
      <!-- <div class="xiangGuanYeWu">
        <p class="TitleP">相关业务</p>
        <div class="xiangGuanYeWu_img">
          <div
            class="items_lists"
            v-for="(item, index) in xiangGuanYeWuArr"
            :key="index"
            @click="xiangguanyewuClick(item.jumpPageUrl)"
          >
            <img :src="item.relateImg" alt="" />
          </div>
        </div>
      </div> -->
    </div>

    <div>
      <img src="../../../assets/guojimaoyi/but.png" alt="" />
    </div>
  </div>
</template>
<script>
// 头尾导入
import xHeader from '../../../components/header.vue'
import countTo from 'vue-count-to'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader,
    countTo
  },
  data () {
    return {
      // 贸易对接 数组
      tradDockingArr: [
        {
          image: require('../../../assets/guojimaoyi/trade1.png'),
          title: '进出口贸易对接'
        },
        {
          image: require('../../../assets/guojimaoyi/trade2.png'),
          title: '采购商与供应商精准匹配'
        },
        {
          image: require('../../../assets/guojimaoyi/trade3.png'),
          title: '境外展会、交流活动对接'
        },
        {
          image: require('../../../assets/guojimaoyi/trade4.png'),
          title: '国内产业聚集区对接'
        }
      ],
      timage: [
        {
          signImg: require('../../../assets/guojimaoyi/t1.jpg'),
          text: '能源'
        },
        {
          signImg: require('../../../assets/guojimaoyi/t2.jpg'),
          text: '粮食'
        },
        {
          signImg: require('../../../assets/guojimaoyi/t3.jpg'),
          text: '肉类'
        },
        {
          signImg: require('../../../assets/guojimaoyi/t4.jpg'),
          text: '大宗商品'
        }
      ],
      timageE: [
        {
          signImg: require('../../../assets/guojimaoyi/t1.jpg'),
          text: 'Energy'
        },
        {
          signImg: require('../../../assets/guojimaoyi/t2.jpg'),
          text: 'Food'
        },
        {
          signImg: require('../../../assets/guojimaoyi/t3.jpg'),
          text: 'Meat'
        },
        {
          signImg: require('../../../assets/guojimaoyi/t4.jpg'),
          text: 'Commodities'
        }
      ],
      // 标志图片得数组
      signImgArr: [
        {
          signImg: require('../../../assets/shangwufuwu/zhaoshangyinzi.png')
        },
        {
          signImg: require('../../../assets/shangwufuwu/jinrongfuwu.png')
        },
        {
          signImg: require('../../../assets/shangwufuwu/kejirencai.png')
        },
        {
          signImg: require('../../../assets/shangwufuwu/shangwufuwu.png')
        },
        {
          signImg: require('../../../assets/shangwufuwu/zhanlanzhanhui.png')
        }
      ],
      // 相关业务 数组
      xiangGuanYeWuArr: [
        {
          relateImg: require('../../../assets/shangwufuwu/11.png'),
          jumpPageUrl: '/chinaEurope_train' // 中欧运输(中欧班列)页面
        },
        {
          relateImg: require('../../../assets/shangwufuwu/22.png'),
          jumpPageUrl: '/international_sea' // 海运服务(国际服务)页面
        },
        {
          relateImg: require('../../../assets/shangwufuwu/33.png'),
          jumpPageUrl: '/international_car' // 汽车运输(国际汽运)页面
        },
        {
          relateImg: require('../../../assets/shangwufuwu/44.png'),
          jumpPageUrl: '/airServices' // 空运服务
        },
        {
          relateImg: require('../../../assets/shangwufuwu/55.png'),
          jumpPageUrl: '/chinaRussian_transport' // 中俄清关
        },
        {
          relateImg: require('../../../assets/shangwufuwu/66.png'),
          jumpPageUrl: '/international_trade' // 国际贸易
        },
        {
          relateImg: require('../../../assets/shangwufuwu/88.png'),
          jumpPageUrl: '/businessServices' // 商务服务
        }
      ]
    }
  },
  methods: {
    /**
     * 相关业务点击事件
     * 肖明霞
     * 2021-11-22
     */
    xiangguanyewuClick (jumpUrl) {
      this.$router.push({
        path: '/ownJumpPage',
        query: {
          pathUrl: jumpUrl
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.guojimaoyi {
  width: var(--width1200px);
  margin: 115px auto 0;
  & > p {
    font-family: Microsoft YaHei;
    color: #666666;
    text-align: left;
    text-indent: 32px;
    line-height: 32px;
  }
  .guojimaoyiImage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 56px;
    .guojimaoyiImageCon {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-top: 56px;
      position: relative;
      .guojimaoyiImageText {
        width: 100%;
        position: absolute;
        bottom: 10px;
        align-items: center;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }
  .textT6 {
    margin-top: 100px;
    margin-bottom: -100px;
    .textT6Title {
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #2e67b1;
      margin-bottom: 26px;
    }
    .textT6Text {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2e67b1;
    }
  }

  .imgT5 {
    width: 100%;
    height: 150px;
    position: relative;
    margin-top: 47px;
    & > img {
      width: 100%;
      height: 100%;
    }
    & .introduce {
      width: 64%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 48px;
      padding-left: 69px;
      .introduceCon {
        .introduceCon_Top {
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;
          .introduceCon_TopOne {
            font-size: 50px;
            font-family: AkzidenzGroteskBQ;
            font-weight: normal;
            color: #ffffff;
            padding: 0;
            margin: 0;
          }
          .introduceCon_TopTwo {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
            margin-bottom: 10px;
            margin-left: 11px;
          }
        }
        .introduceCon_But {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }
  // 内容所有标题
  .TitleP {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2e67b1;
    text-align: left;
    margin: 66px 0 30px 0;
  }

  & .guojimaoyi_kuang {
    background: #ffffff;
    border: 6px solid #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(41, 92, 169, 0.1);
  }
  // MG集团与俄罗斯政府和龙头企业深度合作
  & .deep_cooperate {
    width: 100%;
    .cooperate_main {
      height: 427px;
    }

    // 俄罗斯最大的三家超市的供货商X5零售集团
    .supplier {
      & > .supplier_main {
        width: 100%;
        height: 266px;
      }
    }

    // 俄罗斯肉食龙头塞尔那维雅、布莱恩斯基等企业  and  白俄罗斯进口泥炭土，用于农业种植和改良土壤环境
    .RussianMeat_Belarus {
      & .RussianMeat_Belarus_main {
        width: 100%;
        height: 402px;
      }
    }
  }

  // 俄罗斯肉食龙头塞尔那维雅、布莱恩斯基等企业
  .leadingEnterprise {
    & > .TitleP {
      margin-top: 60px;
      margin-bottom: 32px;
    }
  }

  // 白俄罗斯进口泥炭土，用于农业种植和改良土壤环境
  .BelarusianPeat {
    & > .TitleP {
      margin-top: 71px;
      margin-bottom: 32px;
    }
  }
  //贸易对接
  .trade_docking {
    width: 100%;
    & .TitleP {
      margin-top: 75px;
      margin-bottom: 35px;
    }
    .neirong_kuang {
      display: flex;
      justify-content: space-between;
      .neirong_items {
        width: 282px;
        height: 170px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(41, 92, 169, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > p {
          margin-top: 25px;
          color: #666666;
          font-family: Microsoft YaHei;
        }
      }
    }
  }

  .main_content {
    width: var(--width1200px);
    margin: 65px auto 0;
    // background: pink;
    text-align: left;
    & > p {
      text-indent: 32px;
      font-family: Microsoft YaHei;
      color: #666666;
      line-height: 35px;
    }
    // 标志图片
    .signImg {
      // width:278px;
      // height: 253px;
      // background: orange;
      margin: 52px 0 37px 0;
      display: flex;
      // align-items: center;
      // justify-content: space-between;
      flex-shrink: 1;
      // & img{
      //      width:100%;
      //         height: 100%;
      // }
      .itemsImg {
        // background: orange;
        margin-left: -32px;
        // width:278px;
        // height: 253px;
        // & > img{
        //     width:100%;
        //     height: 100%;
        // }
        // &:first-child{
        //   margin-left: -40px;
        // }
        // & > img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    //交流活动
    .communication_activities {
      width: 100%;
      height: 1130px;
      position: relative;
      & > p {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e67b1;
        text-align: left;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }
      & > img {
        width: 100%;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
    // 相关业务
    .xiangGuanYeWu {
      width: 100%;
      height: 210px;
      // background: red;
      margin: 80px 0 65px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & > p {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e67b1;
      }
      .xiangGuanYeWu_img {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        & .items_lists {
          width: 154px;
          height: 154px;
          transition: all 0.15s linear;
          cursor: pointer;
          & > img {
            width: 100%;
            height: 100%;
          }
          &:hover {
            transform: translateY(-15px);
          }
        }
      }
    }
  }

  // 相关业务
  .xiangGuanYeWu {
    & .TitleP {
      margin-top: 76px;
      margin-bottom: 32px;
    }
    width: 100%;
    // background: red;
    margin: 0px 0 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #2e67b1;
    }
    .xiangGuanYeWu_img {
      display: flex;
      justify-content: space-between;
      & .items_lists {
        width: 154px;
        height: 154px;
        transition: all 0.15s linear;
        cursor: pointer;
        & > img {
          width: 100%;
          height: 100%;
        }
        &:hover {
          transform: translateY(-15px);
        }
      }
    }
  }
}
</style>
